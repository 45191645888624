.title {
  text-align: center;
  font-size: 4rem;
}

.contentContainer {
  text-align: center;
}

.content {
  display: inline-block;
  width: 40rem;
}

.form {
  height: 16rem;
  width: 100%;
  border: none;
  border-top: solid 1px;
  border-bottom: solid 1px;
  padding-top: 1rem;
}

.socialContainer {
  margin-top: 1rem;
  display: flex;
}

.socialLink {
  color: inherit;
  padding: 1rem;
  border: solid 0.2rem transparent;
  border-radius: 1rem;
}

.socialLink:hover {
  border-color: #00bbff;
}

@media screen and (max-width: 40rem) {

  .title {
    font-size: 3rem;
  }

  .content {
    width: 100%;
  }

  .form {
    height: 20rem;
  }

  .socialContainer {
    flex-direction: column;
  }
}